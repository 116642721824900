/*----------------------------------------*\
  PILL
\*----------------------------------------*/

.pill {
  display: inline-block;

  margin-left: 1rem;
  padding: .3rem 1rem;

  vertical-align: middle;

  color: white;
  border-radius: 1em;
  background: $color-strawberry;

  @include font-values($font-small);
}

