/*----------------------------------------*\
  PAGE
\*----------------------------------------*/

body {
  overflow-x: hidden;
  &.b-group {
    padding-top: 6rem;
    @include media('md') {
      padding-top: 8rem;
    }
    @include media('lg') {
      padding-top: 0;
    }
  }
  &.b-organization {
    padding-top: 10rem;
    @include media('md') {
      padding-top: 13rem;
    }
    @include media('lg') {
      padding-top: 0;
    }
  }
}

.stop-transitions {
  *, *:before, *:after {
    transition: none !important;
  }
}
