/*----------------------------------------*\
  HEADLINE
\*----------------------------------------*/

.headline {
  font-family: 'EKW Helvetica Condensed';

  position: relative;

  overflow: hidden;

  padding-left: .24em;

  max-width: 70%;

  @include media('sm') {
    max-width: 50%;
  }
  @include media('md') {
    max-width: 60%;
  }
  @include media('lg') {
    max-width: 42%;
  }
  @include media('xl') {
    max-width: 40%;
  }
}

.headline__text,
.headline__padding {
  div,
  h1 {
    display: inline;

    white-space: pre-wrap;

    box-shadow: 0 2px 0px rgba(0, 0, 0, 0.12);
    .b-group & {
      color: white;
      background: $brand-color;
    }
    .b-organization & {
      background: white;
    }
  }
}

.headline__text {
  position: relative;
  z-index: 1000;
}

.headline__padding {
  position: absolute;
  top: 0;
  left: 0;

  user-select: none;
  div,
  h1 {
    span:after {
      content: '\00a0\A\00a0\A\00a0\A\00a0\A\00a0\A\00a0\A\00a0\A';
    }
  }
}
/**
 * Headline Styles
 */
.headline--h1 {
  letter-spacing: 0;

  @include font-values($font-headline);
}
