/*----------------------------------------*\
  SECTOR
\*----------------------------------------*/

.sector {
  a:hover {
    .sector__icon {
      opacity: .7;
    }
  }
}

.sector-collection {
  columns: 1;
  column-gap: 4rem;
  font-size: 0;
  line-height: 0;
  @include media('sm') {
    columns: 2;
    column-rule: 1px solid $alt-color-light;
  }
  @include media('lg') {
    columns: 3;
  }
  @include media('xl') {
    // columns: 4;
  }
  .sector {
    display: inline-block;
    padding: 0;
    width: 100%;
    font-size: rem(map-get($rhythm-size-xs, size));
    line-height: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;
    &:hover {
      background: $color-grey-50;
    }
    a {
      display: block;
    }
    .pill {
      float: right;
    }
  }
}

/**
 * Elements
 */

.sector__checkbox {
  visibility: hidden;
  position: absolute;
}

.sector__icon {
  width: 5rem;
  height: 5rem;
  padding: rem(8);

  border: 1px solid $alt-color-light;
  border-radius: 100%;

  margin-right: 1.5rem;
  position: relative;

  @include media('md') {
    width: 6rem;
    height: 6rem;
    padding: rem(10);
  }
  svg {
    fill: black;
  }
}

.sector__label {
  display: flex;
  align-items: center;
  padding: 0;

  color: $alt-color-dark;
  span {
    display: inline-block;
  }
}

.sector__count {
  width: 3rem;
  height: 3rem;
  // color: white;
  background: $alt-color-light;
  color: $text-color;
  // border: 1px solid $alt-color-light;
  font-size: rem(12);
  border-radius: 100%;
  line-height: 3rem;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
}


.sector__checkbox:checked + .sector__label {
  // font-weight: bold;
  color: $text-color;
  .sector__icon {
    background: currentColor;
    border-color: currentColor;
    svg {
      fill: white;
    }
  }
}
