/*----------------------------------------*\
  ICON
\*----------------------------------------*/

.icon {
  display: inline-block;

  vertical-align: top;

  fill: currentColor;

}


.icon--full {
  width: 100%;
  height: 100%;
}

.icon--large {
  width: 8rem;
  height: auto;
  max-height: 8rem;
}

.icon--tiny {
  width: 3rem;
  height: auto;
  max-height: 3rem;
}

.icon--match-text {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
