/*----------------------------------------*\
  LIST
\*----------------------------------------*/

li {
  padding: rem(4) 0;
}

.list,
%list {
  margin: 0;
  padding: 0;

  list-style: none;
}
