/*----------------------------------------*\
  BORDERS
\*----------------------------------------*/

.brd {
  border: 1px solid $border-color;
  border-width: 0;
}

.brd--t {
  border-top-width: 1px;
}

.brd--r {
  border-right-width: 1px;
}

.brd--b {
  border-bottom-width: 1px;
}

.brd--l {
  border-left-width: 1px;
}

.brd--v {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.brd--h {
  border-right-width: 1px;
  border-left-width: 1px;
}
