/*----------------------------------------*\
  TYPOGRAPHY SCAFFOLDING
\*----------------------------------------*/

html {
  font-family: $font-family-default;
  font-size: 100% * $rhythm-spacing-base / 16;
  color: $text-color;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include font-values($font-default);
}

.grid-overlay {
  &:before {
    position: absolute;
    z-index: 8999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: auto;
    height: auto;

    content: '';
    pointer-events: none;
    background: url(//basehold.it/i/#{$rhythm-spacing-base}/aaaaaa) repeat top left;
    @media (min-resolution: 2dppx) {
      background: url(//basehold.it/i/#{2 * $rhythm-spacing-base}/aaaaaa) repeat top left;
      background-size: 2px 8px;
    }
  }
}


/**
 * Links
 */

a {
  color: $link-color;
  &:hover, &:focus {
    text-decoration: none;
  }
}


/**
 * Headings
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  font-weight: bold;
  word-break: break-word;
}

.text-h1,
%text-h1 {
  @include font-values($font-h1);
}

.text-h2,
%text-h2 {
  @include font-values($font-h2);
}

.text-h3,
%text-h3 {
  @include font-values($font-h3);
}

.text-h4,
%text-h4 {
  @include font-values($font-h4);
}

.text-h5,
%text-h5 {
  @include font-values($font-h5);
}

.text-h6,
%text-h6 {
  @include font-values($font-h6);
}


h1,
.h1 {
  margin-bottom: 2rem;

  transform: translateY(-1px);
  letter-spacing: -.015em;

  @extend %text-h1;

  @include media('xl') {
    margin-bottom: 5rem;
  }
}

h2,
.h2 {
  margin-top: 1rem;
  margin-bottom: 4rem;

  transform: translateY(-1px);
  letter-spacing: -.01em;

  @extend %text-h2;
}

h3,
.h3 {
  margin: 2rem 0;

  @extend %text-h3;
}

h4,
.h4 {
  margin: 2rem 0 1rem;

  @extend %text-h4;
}

h5,
.h5 {
  margin: 2rem 0;

  @extend %text-h5;
}

h6,
.h6 {
  margin: 2rem 0;

  @extend %text-h6;
}

.text-large {
  @include font-values($font-large);
}

.lg-text-large {
  @include media('lg') {
    @include font-values($font-large);
  }
}

.text-small {
  @include font-values($font-small);
}

.md-down-text-small {
  @include font-values($font-small-mobile);
}

.text-quote {
  @include font-values($font-h4);
}

/**
 * Paragraphs
 */

p {
  margin: 2rem 0 4rem;
}


/**
 * Preformatted text
 */

pre,
code {
  font-family: 'Monaco', monospace;
  font-weight: normal;

  tab-size: 4;

  background-color: $color-grey-100;
}


pre {
  margin: 0;
  padding: 0;

  border-radius: $border-radius-default;
}

code {
  display: inline-block;

  padding: 0;
  pre & {
    display: block;

    padding: 0;
  }
}


/**
 * Lists
 */

ul,
ol {
  margin: 0;
  padding-left: $spacing-unit-default;
  ul,
  ol {
    margin: 0;
  }
}

dl {
  margin: 0;

  @extend %clearfix;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

.dl--inline {
  dt {
    float: left;

    width: 100px;
  }
  dd {
    margin-left: calc(100px + #{$spacing-unit-small});

    @extend %clearfix;
  }
}


/**
 * Miscellaenous
 */

abbr {
  cursor: help;
}

blockquote {
  margin: 0;
}

figure {
  margin: 0;
}

cite {
  font-style: normal;
}

hr {
  box-sizing: border-box;
  height: 1px;
  margin: 0;
  margin: rem($rhythm-spacing-base) 0 rem($rhythm-spacing-base - 1);

  border: none;
  border-top: 1px solid $border-color;
}

code,
kbd,
pre,
samp {
  font-family: 'EKW Roboto Mono', 'Roboto Mono', 'Monaco', 'Courier New', Courier, monospace;
}

