/*----------------------------------------*\
  IMAGE
\*----------------------------------------*/

.img-contact-thumbnail {
  width: 6rem;
  height: 6rem;
  @include media('sm') {
    width: 13rem;
    height: 13rem;
  }
  @include media('xl') {
    width: 18rem;
    height: 18rem;
  }
}

.responsive-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}