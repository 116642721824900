/*----------------------------------------*\
  INPUT RANGE
\*----------------------------------------*/

$noui-css-prefix: 'input-range__';
$noui-css-prefix-modifier: 'input-range--';

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.#{$noui-css-prefix}target,
.#{$noui-css-prefix}target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.#{$noui-css-prefix}target {
  position: relative;
  direction: ltr;
}
.#{$noui-css-prefix}base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
}
.#{$noui-css-prefix}connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.#{$noui-css-prefix}origin {
  position: absolute;
  height: 0;
  width: 0;
}
.#{$noui-css-prefix}handle {
  position: relative;
  z-index: 1;
  &:focus {
    outline: none;
  }
}
.#{$noui-css-prefix-modifier}state-tap .#{$noui-css-prefix}connect,
.#{$noui-css-prefix-modifier}state-tap .#{$noui-css-prefix}origin {
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.#{$noui-css-prefix-modifier}state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.#{$noui-css-prefix}base,
.#{$noui-css-prefix}handle {
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.#{$noui-css-prefix-modifier}horizontal {
  height: 1rem;
}
.#{$noui-css-prefix-modifier}horizontal .#{$noui-css-prefix}handle {
  width: 3rem;
  height: 3rem;
  left: -1rem;
  top: -1rem;
}
.#{$noui-css-prefix-modifier}vertical {
  width: 18px;
}
.#{$noui-css-prefix}vertical .#{$noui-css-prefix}handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.#{$noui-css-prefix}target {
  background: $alt-color-light;
  border-radius: $border-radius-default;
}
.#{$noui-css-prefix}connect {
  background: currentColor;
  border-radius: $border-radius-default;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.#{$noui-css-prefix}draggable {
  cursor: ew-resize;
}
.#{$noui-css-prefix-modifier}vertical .#{$noui-css-prefix}draggable {
  cursor: ns-resize;
}
.#{$noui-css-prefix}handle {
  border: 1px solid $alt-color-lighter;
  border-radius: 100%;
  background: white;
  cursor: default;
  // box-shadow: 0 0 0 2px rgba(black,0.1);
  box-shadow: $material-shadow-default;
  transition: $transition-hover;
  &:after {
    content: "";
    display: block;
    background: $alt-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    top: rem(7);
    left: rem(7);
    transition: $transition-hover;
  }
  
}
.#{$noui-css-prefix}active {
  box-shadow: $material-shadow-elevated;
  transform: scale(1.2);
  &:after {
    background: currentColor;
  }
}



/* Disabled state;
 */

[disabled] .#{$noui-css-prefix}connect {
  background: #B8B8B8;
}
[disabled].#{$noui-css-prefix}target,
[disabled].#{$noui-css-prefix}handle,
[disabled] .#{$noui-css-prefix}handle {
  cursor: not-allowed;
}



/* Base;
 *
 */
.#{$noui-css-prefix}pips,
.#{$noui-css-prefix}pips * {
  box-sizing: border-box;
}
.#{$noui-css-prefix}pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.#{$noui-css-prefix}value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.#{$noui-css-prefix}value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.#{$noui-css-prefix}marker {
  position: absolute;
  background: #CCC;
}
.#{$noui-css-prefix}marker-sub {
  background: #AAA;
}
.#{$noui-css-prefix}marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.#{$noui-css-prefix}pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.#{$noui-css-prefix}value-horizontal {
  transform: translate3d(-50%,50%,0);
}

.#{$noui-css-prefix}marker-horizontal.#{$noui-css-prefix}marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.#{$noui-css-prefix}marker-horizontal.#{$noui-css-prefix}marker-sub {
  height: 10px;
}
.#{$noui-css-prefix}marker-horizontal.#{$noui-css-prefix}marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.#{$noui-css-prefix}pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.#{$noui-css-prefix}value-vertical {
  transform: translate3d(0,50%,0);
  padding-left: 25px;
}

.#{$noui-css-prefix}marker-vertical.#{$noui-css-prefix}marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.#{$noui-css-prefix}marker-vertical.#{$noui-css-prefix}marker-sub {
  width: 10px;
}
.#{$noui-css-prefix}marker-vertical.#{$noui-css-prefix}marker-large {
  width: 15px;
}

.#{$noui-css-prefix}tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: $border-radius-default;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.#{$noui-css-prefix-modifier}horizontal .#{$noui-css-prefix}tooltip {
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}
.#{$noui-css-prefix-modifier}vertical .#{$noui-css-prefix}tooltip {
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}