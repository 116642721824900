/*----------------------------------------*\
  LINKS
\*----------------------------------------*/

a {
  text-decoration: none;
}


a.b-color:not(.link-inverted) {
  &:hover,
  &:focus,
  &:active {
    color: $text-color;
  }
}

a.link-inverted {
  &:not(:hover) {
    color: $text-color;
  }
}

.link-muted {
  color: $muted-color;
  &:hover,
  &:focus,
  &:active {
    color: $text-color;
  }
}

.link-unstyled {
  color: inherit;
}
