/*----------------------------------------*\
  SECTION
\*----------------------------------------*/

.section {
  position: relative;

  overflow: hidden;

  background-color: white;
}

.section__body {
  position: relative;
  z-index: 2;

  width: 100%;
  max-width: $container-width;
  margin: $gutter-xs auto;
  padding: 0 $gutter-xs;

  box-sizing: border-box;

  @include media('sm') {
    margin-top: $gutter-sm;
    margin-bottom: $gutter-sm;
    padding-right: $gutter-sm;
    padding-left: $gutter-sm;
  }
  @include media('md') {
    margin-top: ($gutter-md / 2);
    margin-bottom: ($gutter-md / 2);
    padding-right: $gutter-md;
    padding-left: $gutter-md;
    &:first-child {
      margin-top: $gutter-md;
    }
    &:last-child {
      margin-bottom: $gutter-md;
    }
  }
  @include media('lg') {
    margin-top: ($gutter-lg / 2);
    margin-bottom: ($gutter-lg / 2);
    &:first-child {
      margin-top: $gutter-lg;
    }
    &:last-child {
      margin-bottom: $gutter-lg;
    }
  }
  @include media('xl') {
    margin-top: ($gutter-xl / 2);
    margin-bottom: ($gutter-xl / 2);
    &:first-child {
      margin-top: $gutter-xl;
    }
    &:last-child {
      margin-bottom: $gutter-xl;
    }
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}


.section__body--full {
  width: 100%;
  max-width: none;
  padding: 0;
}

.section__body--wide {
  width: 100%;
  max-width: 1800px;
}

.section__body--narrow {
  width: 100%;
  max-width: $container-width;
}


.section__title {
  margin-bottom: 12rem;
  > :first-child {
    margin-top: 0;
  }
}




/**
 * Display modifiers
 */
.section--bottom {
  display: flex;

  padding-bottom: 5rem;

  align-items: flex-end;
}

.section--hero {
  display: flex;
  align-items: center;
  height: rem($globals-imageStyles-hero-xs-height);
  padding-bottom: 4rem;
  @include media('sm') {
    height: rem($globals-imageStyles-hero-sm-height);
    padding-bottom: 4rem;
  }
  @include media('md') {
    height: rem($globals-imageStyles-hero-md-height);
    padding-bottom: 9rem;
  }
  @include media('lg') {
    height: rem($globals-imageStyles-hero-lg-height);
    padding-bottom: 12rem;
  }
  @include media('xl') {
    height: rem($globals-imageStyles-hero-xl-height);
    padding-bottom: 14rem;
  }
}

.section--hero img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  height: rem($globals-imageStyles-hero-xs-height);
  @include media('sm') {
    height: rem($globals-imageStyles-hero-sm-height);
  }
  @include media('md') {
    height: rem($globals-imageStyles-hero-md-height);
  }
  @include media('lg') {
    height: rem($globals-imageStyles-hero-lg-height);
  }
  @include media('xl') {
    height: rem($globals-imageStyles-hero-xl-height);
  }
}

.section--image {
  background: transparent;
  picture {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


/**
 * Alternative colors
 */

.section--transparent {
  background-color: transparent;
}

.section--alt {
  background-color: $alt-color-lighter;
}

.section--medium {
  background-color: $alt-color;
}

.section--dark {
  color: white;
  background-color: $color-palladium;
}

.section-collection--h {
  .section-collection__title {
    position: sticky;
    z-index: 10;
    top: -10rem;

    margin-bottom: calc(100vh - 24rem);

    background: rgba(white, .97);
    .section__body {
      padding-bottom: 2rem;
    }
  }
  .section-collection__title + .section {
    margin-top: calc((100vh - 24rem) * -1);
    .section__body {
      padding-top: 0;
    }
  }
  .section-collection__image {
    position: sticky;
    top: 0;

    height: 100vh;

    transition: .55s ease-in-out;
    &.appear {
      .section-collection__content {
        transform: none;

        opacity: 1;
      }
      .section-collection__image-default {
        opacity: 0;
      }
      .section-collection__image-overlay {
        opacity: .2;
      }
    }
  }
  .section-collection__image-default,
  .section-collection__image-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: .85s ease-in-out;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .section-collection__image-default {
    opacity: 1;
  }
  .section-collection__image-overlay {
    opacity: 0;
  }
  .section-collection__content {
    display: flex;

    height: 100vh;
    // margin-top: -120vh;

    transition: .85s ease-in-out;
    transform: translateY(10rem);

    opacity: 0;
    background: transparent;

    align-items: center;
  }
}


.section-collection--v {
  display: flex;

  align-items: flex-start;
  .section-collection__title {
    position: sticky;
    top: calc(50% - 25vh);

    flex: 0 0 50%;
  }
  .section-collection__content {
    flex: 0 0 50%;
  }
}


.section-collection--article {
  width: $container-width;
  max-width: 100%;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;

  align-items: flex-start;
  .section-collection__aside {
    position: sticky;
    top: 0;

    flex: 5 0 0;
  }
  .section-collection__content {
    flex: 8 0 0;
  }
}
