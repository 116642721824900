/*----------------------------------------*\
  Z-INDEX
\*----------------------------------------*/

$z-index-box__body:         300;
$z-index-box__header:       303;
$z-index-box__addon:        310;
$z-index-box__bubble:       310;

$z-index-searchbox__header: 400;

$z-index-page-overlay:      450;

$z-index-nav:               486;
$z-index-nav-group:         487;
$z-index-nav-organization:  488;
$z-index-nav-meta:          490;

$z-index-admin-panel:       499;
$z-index-local-tasks:       498;
