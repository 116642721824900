/*----------------------------------------*\
  DRUPAL LANGUAGESWITCHER
\*----------------------------------------*/

#block-languagedropdownswitcher {
  select {
    font-size: 2rem;
    font-weight: bold;

    width: auto !important;
    padding: 0;
    height: 3rem;

    text-transform: uppercase;

    border: none;
    background: none;
  }
  option {
    padding: 0;
    min-height: initial;
  }
}
