/*----------------------------------------*\
  MATERIAL
\*----------------------------------------*/

.material,
%material {
  color: $text-color;
  border-radius: $border-radius-default;
  background: white;
  box-shadow: $material-shadow-default;
}


/**
 * Material States
 */
.material--elevated {
  box-shadow: $material-shadow-elevated;
}

.material--active {
  box-shadow: $material-shadow-active;
}
