/*----------------------------------------*\
  LIST STACKED
\*----------------------------------------*/

.list-inline,
%list-inline {
  @extend %list;
  > li {
    display: inline-block;

    padding: 0 $spacing-unit-default/2;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

// Add separators
.list-inline--divided,
%list-inline--divided {
  > li + li {
    border-left: $list-separator-style;
  }
}

/**
  * Sizes
  */

.list-inline--tight,
%list-inline--tight {
  > li {
    padding: 0;
  }
}

.list-inline--tiny,
%list-inline--tiny {
  > li {
    padding: 0 $spacing-unit-tiny/2;
  }
}

.list-inline--small,
%list-inline--small {
  > li {
    padding: 0 $spacing-unit-small/2;
  }
}

.list-inline--large,
%list-inline--large {
  > li {
    padding: 0 $spacing-unit-large/2;
  }
}

.list-inline--huge,
%list-inline--huge {
  > li {
    padding: 0 $spacing-unit-huge/2;
  }
}
