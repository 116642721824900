/*----------------------------------------*\
  TEXT
\*----------------------------------------*/

/**
 * Alignment
 */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}


/**
 * Case
 */

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}


/**
 * Styling
 */

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}


/**
 * Colors
 */

.text-muted {
  color: $muted-color;
}

.text-white {
  color: white;
}
