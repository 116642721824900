.relative {
  position: relative;
}

.overflow-visible {
  overflow: visible !important;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
.pin {
  position: absolute;
}

.position-static {
  position: static;
}

.pin--t-r {
  top: 0;
  right: 0;
}

.pin--b-l {
  top: 0;
  left: 0;
}

.pin--b-r {
  top: 0;
  right: 0;
}

.pin--center-off {
  transform: translate(25%,-25%);
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex-item--grow-1 {
  flex-grow: 1;
}

.sm-flex--reverse {
  @include media('sm') {
    flex-direction: row-reverse;
  }
}
