/*----------------------------------------*\
  ROTATE
\*----------------------------------------*/

/**
 * Counterclockwise
 */

@keyframes rotate-counterclockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
