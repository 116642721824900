/*----------------------------------------*\
  SEARCHBOX
\*----------------------------------------*/



.no-backdropfilter .section--nav-group {
  .b-group & {
    background: rgba(white, 0.8);
  }
}

.searchbox-gutter {
  padding: $gutter-xs;
  @include media('xl') {
    padding: 3rem;
  }
}

.section--searchbox {
  overflow: visible;
  padding: 2rem 0;
  .section__body {
    margin-top: 0;
    @include media('xs', $breakpoints-desc) {
      padding: 0;
    }
  }
}

.searchbox {
  display: flex;
  flex-direction: column;
  margin-top: -9rem !important;
  @include media('sm') {
    margin-top: -17rem !important;
  }
  @include media('md') {
    margin-top: -19rem !important;
  }
  @include media('lg-xl', $breakpoints-custom) {
    margin-top: -21rem !important;
  }
  @include media('xl') {
    margin-top: -24rem !important;
  }
}

.searchbox__tabs {
  padding-top: 0;
  padding-bottom: 0;
  @include media('sm') {
    background: transparent;
  }
}

.searchbox__title {
  margin: 0;
  flex: 1 0 auto;
  order: 3;
  font-size: rem(map-get($rhythm-size-xxxs, size));
  line-height: 0;
  transform: none;
  margin-top: 0.5rem;
  @include media('xxs', $breakpoints-custom) {
    font-size: rem(map-get($rhythm-size-xs, size));
  }
  @include media('sm') {
    order: 2;
    flex: 0 0 66%;
    margin-top: 0;
    font-size: rem(map-get($rhythm-size-s, size));
  }
  @include media('md') {
    order: 0;
    flex: 0 0 100%;
    margin-bottom: 2rem;
  }
  @include media('lg-xl', $breakpoints-custom) {
    margin-bottom: 0;
    margin-top: 1rem;
    flex: 0 0 percentage(1/3);
  }
  span {
    margin: 0;
    line-height: 2rem;
    @include media('xxs', $breakpoints-custom) {
      line-height: 2.5rem;
    }
    @include media('sm') {
      line-height: 3rem;
    }
  }
  .count, .title {
    .b-group & {
      color: white;
    }
  }
  .count {
    float: left;
    font-size: 4.5rem;
    line-height: 4rem;
    margin-right: 1rem;
    @include media('xxs', $breakpoints-custom) {
      font-size: 5.5rem;
      line-height: 5rem;
    }
    @include media('sm') {
      line-height: 6rem;
      font-size: rem(54);
    }
  }
  .title {
    // @include media('sm') {
    //   font-size: rem(map-get($rhythm-size-m, size));
    // }
    // @include media('lg-xl', $breakpoints-custom) {
    //   font-size: rem(map-get($rhythm-size-l, size));
    //   line-height: 4rem;
    // }
    // @include media('xl') {
    //   font-size: rem(map-get($rhythm-size-xl, size));
    //   line-height: 5rem;
    // }
  }
  .subtext {
    font-weight: normal;
    &:before {
      content: "\A";
      white-space: pre;
    }
    // @include media('sm', $breakpoints-desc) {
    //   font-size: rem(map-get($rhythm-size-xs, size));
    // }
    // @include media('md') {
    //   display: inline-block;
    // }
    // @include media('lg-xl', $breakpoints-custom) {
    //   line-height: 4rem;
    // }
    .b-group & {
      color: rgba(white,0.9);
    }
  }
}

.searchbox__header {
  @include media('sm') {
    border-radius: $border-radius-default;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .b-group & {
    background: $brand-color;
  }
  .b-organization & {
    background-color: $alt-color-lighter;
    @include media('sm') {
      background: rgba(white, 0.95);
    }
  }

  .csspositionsticky & {
    position: sticky;
    top: -8rem;
    z-index: $z-index-searchbox__header;
    @include media('xxs', $breakpoints-custom) {
      top: -10rem;
    }
    @include media('sm') {
      top: -2rem;
    }
    @include media('md') {
      top: 0rem;
    }
  }
}


.searchbox__form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.searchbox__field {
  flex: 0 0 100%;
  position: relative;
  margin-bottom: 2rem;
  @include media('sm') {
    flex: 1 0 40%;
  }
  @include media('md') {
    flex: 1;
    margin-bottom: 0;
  }
  @include media('lg-xl', $breakpoints-custom) {
    flex: 1;
    margin-right: 2rem;
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4rem;
    padding: 1rem;
    @include font-values($font-small, force);
    @include media('xxs', $breakpoints-custom) {
      width: 6rem;
      padding: 1.5rem;
    }
    .b-group & {
      color: white;
    }
  }
  input[type='text'] {
    appearance: none;
    font-weight: bold;
    padding: 0 !important;
    padding-top: rem(6) !important;
    padding-bottom: rem(6) !important;
    padding-left: 4rem !important;
    padding-right: 3rem !important;
    line-height: 3rem !important;
    border-width: 2px;
    @include media('xxs', $breakpoints-custom) {
      line-height: 4rem !important;
      padding-left: 6rem !important;
    }
    @include media('lg-xl', $breakpoints-custom) {
      padding-top: rem(14) !important;
      padding-bottom: rem(14) !important;
    }
    .b-group & {
      background: $brand-color !important;
      border-color: rgba(white, 0.5) !important;
      color: white;
    }
    .b-organization & {
      background: white !important;
      border-color: $alt-color-light !important;
    }
    &::-webkit-input-placeholder {
      font-weight: normal;
      .b-group & {
        color: rgba(white, 0.8);
      }
    }
    &:focus {
      .b-group & {
        border-color: rgba(white,0.8) !important;
        box-shadow: 0 0 0 2px rgba($color-mint-dark,0.5) !important;
      }
      .b-organization & {
        border-color: $alt-color !important;
        box-shadow: 0 0 0 2px rgba($alt-color-darker,0.05) !important;
      }
    }
  }
  .icon {
    width: 100%;
    height: 100%;
    .b-group & {
      fill: white;
    }
  }
}

.searchbox__field--term {
  @include media('sm') {
    margin-right: 1rem;
  }
}

.searchbox__field--location {
  @include media('sm') {
    margin-left: 1rem;
  }
}

.searchbox__button {
  order: 4;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  @include media('sm') {
    flex: 1;
  }
  @include media('md') {
    margin-left: 2rem;
    flex: 0;
  }
  @include media('lg-xl', $breakpoints-custom) {
    margin-left: 0;
  }
  .btn {
    line-height: 3rem;
    width: 100%;
    padding: rem(7) 1rem;
    font-size: rem(map-get($rhythm-size-xxs, size));
    @include media('xxs', $breakpoints-custom) {
      font-size: rem(map-get($rhythm-size-xs, size));
      line-height: 4rem;
      padding: $btn-padding-v $btn-padding-h;
    }
    @include media('sm') {
      width: auto;
    }
    @include media('lg') {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    @include media('lg-xl', $breakpoints-custom) {
      padding-top: rem(15);
      padding-bottom: rem(15);
    }
    @include media('xl') {
      font-size: rem(map-get($rhythm-size-s, size));
    }
    .b-group & {
      background: white;
      color: $brand-color;
      border-color: white;
      // box-shadow: 0 2px 0px rgba($text-color,0.1);
    }
    .b-organization & {
      // box-shadow: 0 2px 0px rgba($text-color,0.1);
    }
  }
}

.searchbox__filter {
  flex: 0 0 100%;
  background: #ffffff;
  border-bottom: 1px solid $alt-color-light;
  display: flex;
  position: relative;
  padding-top: rem(15);
  padding-bottom: rem(15);
  @include media('xs', $breakpoints-desc) {
    padding: 0;
    .searchbox__filter-list {
      .searchbox__filter-item:last-child {
        padding-right: 1rem;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2rem;
      background: linear-gradient(to right, rgba(white,0), rgba(white,0.9) 60%);
    }
  }
}

.searchbox__filter-list {
  display: flex;
  align-items: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  @include media('sm') {
    padding: 0;
  }
  @include media('md') {
    overflow: hidden;
  }
}

.searchbox__filter-item {
  .btn {
    font-weight: normal;
    background: white;
    border-radius: 10rem;
    margin-right: 1rem;
    padding-top: rem(3);
    padding-bottom: rem(3);
    padding-left: 1.8rem;
    padding-right: 1.5rem;
    // color: $alt-color-dark;
    border: 1px solid white;
    transition: $transition-hover;
    font-size: 2rem;

    &:hover {
      // border-color: $alt-color;
      background: $alt-color-lighter;
    }
    &:after {
      content: "↓";
      font-weight: bold;
      display: inline-block;
      margin-left: 0.4em;
      font-size: 2rem;
      line-height: 1rem;
      vertical-align: -.125em;
      transition: $transition-context-change;
    }
    &.active {
      color: $text-color;
      // border-color: $alt-color;
      background: $alt-color-lighter;
      &:after {
        transform: rotateX(180deg) translateY(0.5px);
        // color: $text-color;
      }
    }
  }
}

.searchbox__filter-title {
  display: none;
  @include media('md') {
    display: block;
  }
  @include media('lg-xl', $breakpoints-custom) {
    margin: 0;
    flex: 0 0 percentage(1/3);
  }
}

.searchbox__filter-detail {
  background: white;
  @include media('lg-xl', $breakpoints-custom) {

  }
}




.filter {
  display: flex;
  justify-content: space-around;
}

.filter__icon {
  width: 8rem;
  height: 8rem;
  border: 1px solid $alt-color-light;
  border-radius: 100%;
  padding: 4rem;
  @include media('md') {
    width: 16rem;
    height: 16rem;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

.filter__title {
  text-align: center;
  margin-top: 2rem;
}

.different {
  .searchbox {
    margin-top: -9rem !important;
    @include media('xl') {
      margin-top: -10rem !important;
    }
  }
  .section--searchbox {
    .section__body {
      max-width: 100%;
      width: 100%;
      margin: 0 !important;
      padding: 0;
    }
  }
}