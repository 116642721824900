/*----------------------------------------*\
  ARTICLE
\*----------------------------------------*/

.article {
  margin-top: 5rem;
}



.article__hero {
  height: 40rem;
  background-size: cover;
  background-position: center;
  .responsive-image {
    height: 100%;
  }
  @include media('md') {
    height: 50rem;
  }
  @include media('xl') {
    height: 35vw;
  }
}

/**
 * Display modifiers
 */




/**
 * Alternative colors
 */

