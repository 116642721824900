/*----------------------------------------*\
  FONTS
\*----------------------------------------*/

@font-face {
  font-family: 'EKW Helvetica';
  src: url('../fonts/helvetica-roman.woff') format('woff');
}

@font-face {
  font-family: 'EKW Helvetica';
  font-weight: 200;
  src: url('../fonts/helvetica-light.woff') format('woff');
}

@font-face {
  font-family: 'EKW Helvetica';
  font-weight: bold;
  src: url('../fonts/helvetica-bold.woff') format('woff');
}

@font-face {
  font-family: 'EKW Helvetica Condensed';
  font-weight: bold;
  src: url('../fonts/helvetica-condensed-bold.woff') format('woff');
}

@font-face {
  font-family: 'EKW Roboto Mono';
  font-weight: bold;
  // src: url('../fonts/roboto-mono.woff2') format('woff2');
  src: url('../fonts/roboto-mono-custom.woff') format('woff');
}