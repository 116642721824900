/*----------------------------------------*\
  TEASER ORGANIZATION
\*----------------------------------------*/

@keyframes row1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.teaser-organization {
  position: relative;

  overflow-x: auto;
  overflow-y: auto;

  height: 16rem;
  margin-top: 4rem;

  transition: 1.9s cubic-bezier(.12, .43, .38, 1);

  -webkit-overflow-scrolling: touch;
  @include media('sm') {
    height: 21rem;
  }
  @include media('md') {
    height: 26rem;
  }
  @include media('lg') {
    overflow: visible;

    height: 32rem;
    margin: 12rem 0 16rem;

    -webkit-overflow-scrolling: auto;
  }
  @include media('xl') {
    height: 36rem;
  }
}

/**
 * Elements
 */

.teaser-organization__row {
  position: absolute;
  top: 0;
  left: 1rem;
  padding-right: 1rem;

  display: flex;
  @include media('sm') {
    left: 3rem;
    padding-right: 3rem;
  }
  @include media('md') {
    left: 4.5rem;
    padding-right: 4.5rem;
  }
  @include media('lg') {
    will-change: transform;
    padding-right: 0;
  }
  &:nth-child(1) {
    @include media('sm') {
      left: 3rem;
    }
    @include media('md') {
      left: 4.5rem;
    }
    @include media('lg') {
      top: 0rem;
      left: 0rem;
    }
    .teaser-organization__item {
      &:before {
        top: 0;

        padding-bottom: 2rem;

        transform: translateY(-80%);
      }
      &:hover:before {
        transform: translateY(-100%);

        opacity: 1;
      }
    }
  }
  &:nth-child(2) {
    top: 8rem;
    left: 8rem;
    @include media('sm') {
      top: 10rem;
      left: 12rem;
    }
    @include media('md') {
      top: 13rem;
      left: 16rem;
    }
    @include media('lg') {
      top: 18rem;
      left: 0rem;
    }
    @include media('xl') {
      top: 20rem;
    }
    .teaser-organization__item {
      &:before {
        bottom: 0;

        padding-top: 2rem;

        transform: translateY(80%);
      }
      &:hover:before {
        transform: translateY(100%);

        opacity: 1;
      }
    }
  }
}

.teaser-organization__item {
  position: relative;

  display: flex;

  width: 12rem;
  height: 6rem;
  margin: 0 ($gutter-xs / 2);
  margin-bottom: $gutter-xs;

  background: white;
  box-shadow: $material-shadow-default;

  flex: 0 0 12rem;
  justify-content: center;
  align-items: center;
  @include media('sm') {
    height: 8rem;
    width: 16rem;
    flex: 0 0 16rem;
  }
  @include media('md') {
    margin: 0 1.5rem;
    height: 10rem;
    width: 20rem;
    flex: 0 0 20rem;
  }
  @include media('lg') {
    width: 28rem;
    height: 14rem;
    margin: 0 2rem;

    transition: $transition-hover;

    flex: 0 0 28rem;
    will-change: opacity;
    .teaser-organization:hover & {
      opacity: 0.5;
      .teaser-organization__logo--color {
        opacity: 0;
      }
    }
    &:hover {
      opacity: 1 !important;
      .teaser-organization__logo--color {
        opacity: 1 !important;
      }
    }
    &:before {
      font-weight: bold;

      position: absolute;
      left: 0;

      display: block;

      width: 100%;

      content: attr(data-name);
      transition: $transition-hover-bounce;
      text-align: center;

      opacity: 0;

      will-change: transform, opacity;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -2rem;
      left: -2rem;
      right: -2rem;
      bottom: -2rem;
    }
  }
  @include media('xl') {
    width: 32rem;
    height: 16rem;

    flex: 0 0 32rem;
  }
}

.teaser-organization__logo {
  position: absolute;
  top: 1rem;
  left: 2rem;
  right: 2rem;
  bottom: 1rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @include media('lg') {
    top: 2rem;
    left: 5rem;
    right: 5rem;
    bottom: 2rem;
  }
}

.teaser-organization__logo--color {
  transition: $transition-hover;
}

.teaser-organization__logo--monochrome {
  filter: grayscale(100%);
  z-index: -1;
}
