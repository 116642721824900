/*----------------------------------------*\
  DRUPAL SPECIFIC
\*----------------------------------------*/

#block-ekw-local-tasks {
  position: absolute;
  z-index: $z-index-local-tasks;
  top: 0;
  left: 0;

  width: 100%;

  background: none;
  ul {
    overflow: hidden;

    padding: 0;

    list-style: none;
  }
  li {
    float: right;

    margin-left: 2rem;

    @include font-values($font-small);
  }
  a {
    opacity: .5;
    color: $text-color;
    &:hover {
      opacity: 1;
    }
  }
}

.layout-content {
  position: relative;
}

.paragraph--type--companies h2 {
  @include media('md') {
    white-space: pre;
  }
}
