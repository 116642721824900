/**
 * Return font-size and line-height for a font.
 * When adding the $force parameter, it will add !important
 * in order to override it HARD.
 */
@mixin font-values($font, $force: null) {
  @if ($force == force) {
    $force: !important;
  }
  
  @each $breakpoint, $values in $font {
    $fontsize: #{map-get($values, size) / $rhythm-spacing-base}rem $force;
    $lineheight: #{map-get($values, leading)}rem $force;

    @if ($breakpoint == xs) {
      font-size: $fontsize;
      line-height: $lineheight;
    } @else {
      @include media($breakpoint) {
        font-size: $fontsize;
        line-height: $lineheight;
      }
    }
  }
}