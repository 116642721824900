/*----------------------------------------*\
  COLUMNS
\*----------------------------------------*/

.columns {
  @include media('sm') {
    columns: 2;
    column-gap: $spacing-unit-default;

    li {
      display: inline-block;
      width: 100%;
    }
  }
}
