/**
 * Layout
 */

$container-width:       1416px;

$gutter-xs:             2rem;
$gutter-sm:             4rem;
$gutter-md:             6rem;
$gutter-lg:             12rem;
$gutter-xl:             16rem;

/**
 * Colors
 */

$color-web-black:       #333333;
$color-black:           #000000;
$color-web-white:       #F7F7F5;
$color-white:           #FFFFFF;
$color-white-lucent:    rgba(white, 0.8);;

$color-grey-50:         #f7f7f7;
$color-grey-100:        #f5f7f6;
$color-grey-200:        #E3E7E6;
$color-grey-300:        #D3DAD8;
$color-grey-400:        #C6CCCA;
$color-grey-500:        #B5BBB9;
$color-grey-600:        #898A81;
$color-grey-700:        #6E6E64;
$color-grey-800:        #5F5F58;
$color-grey-900:        #50504D;
$color-grey-1000:       #414141;

$color-mint:            #41B297;
$color-mint-light:      #82c7b1;
$color-mint-dark:      #32977f;

$color-strawberry:      #d82243;

$color-palladium:       #393e48;

/**
 * Spacing & Typography
 */

$rhythm-spacing-base:    8;
$rhythm-font-base:       16;



// Let's define our font sizes we will use across
// all breakpoints
$rhythm-size-xxxl: (
  size: 56,
  leading: 7
);

$rhythm-size-xxl: (
  size: 46,
  leading: 6
);

$rhythm-size-xl: (
  size: 34,
  leading: 5
);

$rhythm-size-l: (
  size: 29,
  leading: 5
);

$rhythm-size-m: (
  size: 23,
  leading: 4
);

$rhythm-size-s: (
  size: 20,
  leading: 4
);

$rhythm-size-xs: (
  size: 16,
  leading: 3
);

$rhythm-size-xxs: (
  size: 14,
  leading: 2
);

$rhythm-size-xxxs: (
  size: 12,
  leading: 2
);

// Assign the font sizes to specific breakpoints
// for headings and regular text
$font-headline: (
  xs: (
    size: 22,
    leading: 4
  ),
  sm: (
    size: 28,
    leading: 5
  ),
  md: (
    size: 34,
    leading: 6
  ),
  lg: (
    size: 40,
    leading: 7
  ),
  xl: (
    size: 46,
    leading: 8
  )
);

$font-h1: (
  xs: $rhythm-size-xl,
  lg: $rhythm-size-xxxl
);

$font-h2: (
  xs: $rhythm-size-m,
  sm: $rhythm-size-l,
  md: $rhythm-size-xl,
  lg: $rhythm-size-xxl
);

$font-h3: (
  xs: $rhythm-size-m,
  lg: $rhythm-size-xl
);

$font-h4: (
  xs: $rhythm-size-s,
  lg: $rhythm-size-l
);

$font-large: (
  xs: $rhythm-size-xs,
  sm: $rhythm-size-s
);

$font-h5: (
  xs: $rhythm-size-xs,
  md: $rhythm-size-s,
  xl: $rhythm-size-m
);

$font-h6: (
  xs: $rhythm-size-xs,
  xl: $rhythm-size-s
);

$font-default: (
  xs: (
    $rhythm-size-xs
  )
);

$font-small: (
  xs: (
    $rhythm-size-xxs
  )
);

$font-small-mobile: (
  xs: $rhythm-size-xxs,
  md: $rhythm-size-xs
);

$font-menu: (
  xs: $rhythm-size-xs,
  lg: $rhythm-size-xxs,
  xl: $rhythm-size-xs
);

/**
 * Shadows
 */

$material-shadow-default:     0 2px 2px 0px rgba(128, 140, 136, 0.13);
$material-shadow-active:      0 1px 2px 0 rgba(#83908c, .21);
$material-shadow-elevated:    0 2px 16px 0 rgba(#83908c, .21);

/**
 * Transitions
 */
$transition-hover:           0.20s ease-in;
// $transition-context-change:  0.60s cubic-bezier(0.65, 0.62, 0.12, 1.00);
// $transition-context-change:  0.6s cubic-bezier(0.88, -0.15, 0.47, 1.0);
$transition-context-change:  0.6s cubic-bezier(0.74, 0.33, 0.15, 1.0);
$transition-hover-bounce:    0.35s cubic-bezier(0.65, 0.62, 0.12, 2.06);

