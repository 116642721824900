/*----------------------------------------*\
  BOX
\*----------------------------------------*/

.box-container {
  margin: 0;
  @include media('lg') {
    display: flex;

    flex-wrap: wrap;
  }
  .box {
    margin-bottom: $gutter-xs;
    &:last-child {
      margin-bottom: 0;
    }
    @include media('sm') {
      margin-bottom: $gutter-sm;
    }
    @include media('md') {
      margin-bottom: $gutter-md / 2;
    }
    @include media('lg') {
      flex: 1 1 0;
      margin-bottom: 0;
      margin-right: $gutter-md / 2;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}


.box,
%box {
  position: relative;

  display: flex;
  flex-direction: column;

  transition: $transition-hover;

  color: $text-color;
  border-radius: $border-radius-default;
  background: white;
  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';
    transition: $transition-hover;

    box-shadow: $material-shadow-default;
  }
  .section--default & {
    background: $alt-color-lighter;
    box-shadow: none;
  }
  @media (min-width: $screen-box-min) {
    flex-direction: row;
  }
  @include media('lg') {
    &:hover {
      transform: translateY(2px);
      &:before {
        transform: translateY(-4px);
      }
      .box__header {
        &:after {
          opacity: .1;
        }
      }
    }
  }
}


.box--default {
  background: $alt-color-light;
  padding: 1rem;
  display: block;
  &:before {
    display: none;
  }
  &:hover {
    transform: none;
  }
}

/**
 * Elements
 */

.box__header {
  position: relative;
  z-index: $z-index-box__header;

  height: rem($globals-imageStyles-teaser-xs-height);

  transition: $transition-hover;

  overflow: visible;

  .responsive-image {
    height: 100%;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';
    transition: $transition-hover;

    opacity: 0;
    background: black;
  }

  @include media('sm') {
    height: auto;
    min-height: rem($globals-imageStyles-teaser-sm-height);

    flex: 1 1 50%;
    order: 1;
  }
  @include media('lg') {
    min-height: rem($globals-imageStyles-teaser-lg-height);

    flex: 1 1;
  }
  @include media('xl') {
    min-height: rem($globals-imageStyles-teaser-xl-height);
  }

}

.box__header img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  height: 100%;
  min-height: rem($globals-imageStyles-teaser-xs-height);
  @include media('sm') {
    min-height: rem($globals-imageStyles-teaser-sm-height);
  }
  @include media('md') {
    min-height: rem($globals-imageStyles-teaser-md-height);
  }
  @include media('lg') {
    min-height: rem($globals-imageStyles-teaser-lg-height);
  }
  @include media('xl') {
    min-height: rem($globals-imageStyles-teaser-xl-height);
  }
}

.box__body {
  z-index: $z-index-box__body;

  padding: 1.5rem 2rem;

  background: white;

  flex: 1 1;

  display: flex;
  flex-direction: column;

  .section--default & {
    background: $alt-color-lighter;
  }
  @include media('lg') {
    flex: 1 1;
    padding: ($spacing-unit-default - 0.5rem) $spacing-unit-default ($spacing-unit-default - 1rem);
  }
  @include media('xl') {
    .box--with-image & {
      min-height: 38rem;
    }
  }
  @media (min-width: $screen-box-min) {
    flex: 1 1 50%;
  }
}


.box__title {
  margin-bottom: 2rem;

  flex: 1 1;
  @include media('sm') {
    flex: none;
  }
  > * {
    margin: 0;
  }
}


.box__text {
  margin-bottom: 2rem;

  flex: 1 1 0;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

.box__addon {
  z-index: $z-index-box__addon;
}

.box__text:not(.box__text--important) {
  .box--with-image & {
    display: none;
    @include media('sm') {
      display: block;
    }
  }
}

/**
 * Styles
 */

.box--material {
  box-shadow: $material-shadow-default;
}

.box--alt,
%box--alt {
  color: $box-default-color;
  background-color: $alt-color-lighter;
}

/**
 * Box Variations
 */

.box-container--v .box {
  @include media('lg') {
    flex-direction: column;
    .box__header {
      order: 0;
      flex: none;
    }
  }
  @include media('lg') {
    .box__text {
      margin-bottom: 4rem;
    }
  }
}

.box-container--h .box {
  @include media('md') {
    .box__header {
      flex: 1 1 50%;
    }
    .box__body {
      flex: 1 1 50%;
    }
  }
  @media (min-width: $screen-box-min) {
    .box__header {
      order: 0;
    }
  }
}

.box-container--small.box-container--h .box {
  @include media('lg') {
    .box__header {
      flex: 1 1;
    }
    .box__body {
      flex: 2 1 6rem;
    }
  }
}

.box-container--right.box-container--h .box {
  .box__header {
    @media (min-width: $screen-box-min) {
      order: 2;
    }
  }
}

.box--video {
  .box__header {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('lg') {
      min-height: 52rem;
    }
    @include media('xl') {
      min-height: 60rem;
    }
  }
  .box__bubble {
    opacity: 0;
  }
  &:hover {
    .box__bubble {
      opacity: 1;
      transform: scale(1);
    }
  }
}


.box--with-bubble {
  .box__title {
    padding-right: 7rem;

    @media (min-width: $screen-box-min) {
      padding-right: 3rem;
    }
    @include media('lg') {
      padding-right: 8rem;
    }
    @include media('lg') {
      padding-right: 11rem;
    }
  }
  .box__text p {
    &:before {
      content: "";
      float: right;
      display: block;
      width: 4rem;
      height: 6rem;
      @include media('lg') {
        height: 3rem;
      }
    }
  }
  .box__bubble {
    position: absolute;
    z-index: $z-index-box__bubble;
    right: 1rem;
    bottom: 0;

    width: 8rem;
    height: 8rem;
    padding: 1.75rem;

    transform: translateY(50%);

    border: .5rem solid white;

    .icon {
      fill: white;
    }

    @media (min-width: $screen-box-min) {
      top: 1rem;
      right: initial;
      bottom: initial;
      left: 0;

      transform: translateX(-50%);
    }

    @include media('sm') {
      width: 10rem;
      height: 10rem;
    }
    @include media('lg') {
      top: initial;
      right: 2rem;
      bottom: 0;
      left: initial;

      padding: 2.25rem;

      width: 10rem;
      height: 10rem;

      transform: translateY(50%);
    }
    @include media('xl') {
      width: 13rem;
      height: 13rem;

      border-width: 1rem;
      right: 1.5rem;
    }
    &:after {
      z-index: -1;
    }
  }
}

.box--benefits {
  margin-top: 2rem;
  @include media('sm') {
    padding-right: 15rem;
  }
  @include media('md') {
    margin-top: 0;
    padding-right: 9rem;
  }
  @include media('lg') {
    margin-right: 4rem;
  }
}
