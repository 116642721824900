/*----------------------------------------*\
  BRAND
\*----------------------------------------*/

.b-group {
  // Left blank just for reference
}

.b-organization {
  // Left blank just for reference
}

.b-color {
  // Left blank just for reference
}

.b-background {
  // Left blank just for reference
}

.b-border {
  // Left blank just for reference
}

.b-group {
  .b-color, .b-color:before, .b-color:after, .b-color-before:before, .b-color-after:after {
    color: $brand-color;
  }
  .b-background {
    background-color: $brand-color;
  }
  .b-border {
    border-color: $brand-color;
  }
  .b-fill {
    fill: $brand-color;
  }
}


/**
 * Group Brand Classes
 */

// Some elements have to be always in the global
// brand color (dirty mint), that's why we need
// specific classes for them.

// The additional specifity with important
// is needed to force a section to have a background
// with the global brand color.
.b-group-background {
  background: $brand-color !important;
}

.b-group-color {
  &, &:before, &:after {
    color: $brand-color;
  }
}
