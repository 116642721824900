/*----------------------------------------*\
  TABLE
\*----------------------------------------*/

table {
  margin-bottom: 0;
}

th,
td {
  padding: 0;
}

.table {
  border-collapse: separate;
  thead th {
    padding: rem(7) 0;

    border-bottom: 2px solid $alt-color-lighter;
  }
  td {
    padding: rem(7) 0;
  }
  tbody tr {
    td {
      border-top: 1px solid transparent;
      border-bottom: 1px solid $alt-color-lighter;
    }
  }
  tfoot th {
    padding: rem(7) 0 rem(7);

    border-top: 2px solid $alt-color-lighter;
  }
}



.table--small {
  td,
  th {
    @include font-values($font-h6);
  }
}
