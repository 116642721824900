/*----------------------------------------*\
  LAYOUTBREAKER
\*----------------------------------------*/

.layoutbreaker {
  margin: 0 !important;
}

.layoutbreaker__aside {
  height: 75vw;

  .responsive-image {
    width: 100%;
    height: 100%;
  }

  @include media('sm') {
    height: 50rem;
  }
  @include media('md') {
    height: 70rem;
  }
  @include media('lg') {
    height: 80rem;
  }
  @include media('xl') {
    height: 100rem;
  }
}


.layoutbreaker__text {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

.layoutbreaker--left,
.layoutbreaker--right {
  @include media('md') {
    display: flex;
  }
  .layoutbreaker__main {
    @include media('md') {
      display: flex;

      align-items: center;
      flex: 1;
    }
    @include media('xl') {
      justify-content: flex-end;
    }
  }
  .layoutbreaker__aside {
    @include media('md') {
      flex: 0 0 50%;
    }
    @include media('xl') {
      flex: 0 0 percentage(1/3);
      flex: 0 0 calc(50% - 29.5rem);
    }
  }
}

.layoutbreaker--right {
  .layoutbreaker__main {
    @include media('xl') {
      justify-content: flex-end;
    }
  }
}

.layoutbreaker--left {
  .layoutbreaker__main {
    @include media('xl') {
      justify-content: flex-start;
    }
  }
  .layoutbreaker__text {
    @include media('xl') {
      margin-left: 31rem;
      max-width: 81rem;
    }
  }
}

.layoutbreaker--right {
  .layoutbreaker__aside {
    @include media('md') {
      order: 2;
    }
  }
  .layoutbreaker__text {
    @include media('xl') {
      margin: 0 31rem 0 auto;
      max-width: 81rem;
    }
  }
}
