/*----------------------------------------*\
  FORMS
\*----------------------------------------*/

/**
 * Basic form elements
 */

fieldset {
  margin: 0;
  padding: 0;

  border: 0;
}

input[type='text'],
input[type='search'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='number'],
input[type='file'],
select,
textarea {
  width: 100%;
  padding: $field-padding-v $field-padding-h;

  vertical-align: bottom;

  color: $field-color;
  border: $field-border;
  border-radius: $field-border-radius;
  background-color: $field-bkg-color;

  @include font-values($field-font);
  // Cleaner font rendering
  // <input> doesn’t inherit from body
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  &:focus {
    border-color: $field-focus-border-color;
    outline: none;
  }
  &[disabled] {
    cursor: not-allowed;

    color: $field-disabled-color;
    background-color: $field-disabled-bkg-color;
  }
}

input[type='file'] {
  font-size: rem(map-get($rhythm-size-s, size));
}

input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

select {
  height: 5rem;
  padding-top: 0;
  padding-bottom: 0;
}

textarea {
  overflow-x: hidden;

  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  label & {
    margin-right: $spacing-unit-tiny;
  }
}

label {
  display: block;

  padding-bottom: $spacing-unit-small;

  cursor: pointer;
}

.label--inline {
  display: inline-block;

  padding-top: ratio($spacing-unit-small);
  padding-bottom: ratio($spacing-unit-small);
  &:not(:last-child) {
    margin-right: $spacing-unit-default;
  }
}


/**
 * Forms layout
 */

.form-group {
  ~ .form-group {
    margin-top: $spacing-unit-small;
  }
}

.field-label {
  font-weight: bold;

  padding-bottom: $spacing-unit-tiny;
}

.field-help {
  margin-top: $spacing-unit-tiny;
  margin-bottom: 0;

  color: $field-help-color;
}


/**
 * Sizes
 */

.input--small,
.select--small {
  border-radius: $field-small-border-radius !important;

  @include font-values($field-small-font, force);
}

.input--small {
  padding: $field-small-padding-v $field-small-padding-h !important;
}

.select--small {
  height: (2 * $field-small-padding-v) + 4;
  padding-right: $field-small-padding-h !important;
  padding-left: $field-small-padding-h !important;
}


.input--large,
.select--large {
  border-radius: $field-large-border-radius !important;

  @include font-values($field-large-font, force);
}

.input--large,
%input--large {
  padding: $field-large-padding-v $field-large-padding-h !important;
}

.select--large {
  height: (2 * $field-large-padding-v) + 5;
  padding-right: $field-large-padding-h !important;
  padding-left: $field-large-padding-h !important;
}

.input--responsive {
  @include media('lg') {
    padding: $field-large-padding-v $field-large-padding-h !important;
    @include font-values($field-large-font, force);
  }
}
