/*----------------------------------------*\
  SWIPE SLIDER
\*----------------------------------------*/

.swipe__item {
  .section__body {
    width: auto;
  }
}

/**
 * Elements
 */

.slider__controls {
  display: flex;
}

.slider__control-btn {
  font-size: 3.6rem;
  line-height: 5.4rem;

  display: none;

  width: 6rem;
  height: 6rem;

  border-radius: 100%;
  background: white;

  @include media('md') {
    z-index: 1;

    display: block;
  }
  span {
    line-height: 1;

    display: inline-block;

    vertical-align: .05em;
  }
}

.slider__indicator {
  line-height: 0;

  margin-bottom: 2rem;

  text-align: center;

  flex: 1 1 0;
  @include media('md') {
    margin-bottom: 0;
  }
}

.swiper-pagination-bullet {
  display: inline-block;

  width: 4rem;
  height: 4rem;
  margin-right: 1rem;

  transition: $transition-context-change;
  transform: scale(.75);

  opacity: .5;
  border-radius: 100%;
  background-position: center;
  background-size: cover;

  @include media('md') {
    width: 6rem;
    height: 6rem;
    margin-right: 1.5rem;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    transform: scale(.9);
  }
  &.swiper-pagination-bullet-active,
  &.swiper-pagination-bullet-active:hover {
    transform: none;

    opacity: 1;
  }
}

.slider__overlay {
  position: absolute;
  z-index: -1;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: $alt-color-lighter;

  filter: blur(36px);
}

.slider__overlay-image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition: $transition-context-change;
  transition-property: opacity;

  opacity: 0;
  background-position: center;
  background-size: cover;
  &.active {
    opacity: .08;
  }
}
