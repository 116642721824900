@import '../../../../../tmp/globalsettings/globalsettings';

// /*----------------------------------------*\
//   GLOBAL SETTINGS

//   Check the following page for details:
//   kanbasu.liip.ch/settings.html
// \*----------------------------------------*/

// // Use this setting to prefix all the components classes
// $namespace:                         '' !default;


// /**
//  * Colors
//  */

$brand-color:                       $color-mint;
$alt-color-lighter:                 $color-grey-100;
$alt-color-light:                   $color-grey-200;
$alt-color:                         $color-grey-500;
$alt-color-dark:                    $color-grey-700;
$alt-color-darker:                  $color-grey-900;


// /**
//  * Typography
//  */

$font-family-default:               'EKW Helvetica', Helvetica, Arial, sans-serif;

$font-size-default:                 rem(map-get($rhythm-size-xs, size));
$font-size-small:                   rem(map-get($rhythm-size-xxs, size));
$font-size-large:                   rem(map-get($rhythm-size-m, size));

$line-height-default:               map-get($rhythm-size-s, leading) / map-get($rhythm-size-s, size);

$text-color:                        #111111;
$link-color:                        inherit;
$muted-color:                       transparentize($text-color,.4);


// /**
//  * Spacings
//  */

// $ratio:                             1.61803398875 !default;

$spacing-unit-default:              3rem;
$spacing-unit-tiny:                 1rem;
$spacing-unit-small:                2rem;
$spacing-unit-large:                5rem;
$spacing-unit-huge:                 7rem;


// /**
//  * Responsiveness
//  */

// // Use EM media-queries for better browser consistency
// // See http://zellwk.com/blog/media-query-units
$screen-xs-max:                     ($globals-breakpoints-xs-max + 0px) / 16px * 1em;
$screen-sm-min:                     ($globals-breakpoints-sm-min + 0px) / 16px * 1em;
$screen-sm-max:                     ($globals-breakpoints-sm-max + 0px) / 16px * 1em;
$screen-md-min:                     ($globals-breakpoints-md-min + 0px) / 16px * 1em;
$screen-md-max:                     ($globals-breakpoints-md-max + 0px) / 16px * 1em;
$screen-lg-min:                     ($globals-breakpoints-lg-min + 0px) / 16px * 1em;
$screen-lg-max:                     ($globals-breakpoints-lg-max + 0px) / 16px * 1em;
$screen-xl-min:                     ($globals-breakpoints-xl-min + 0px) / 16px * 1em;

$mq-xs:                             '(max-width: #{$screen-xs-max})'; // DEPRECATED
$mq-sm:                             '(min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})' !default; // DEPRECATED
$mq-md:                             '(min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})' !default; // DEPRECATED
$mq-lg:                             '(min-width: #{$screen-lg-min})'; // DEPRECATED

$breakpoints-default:               (
                                      'sm' '(min-width: #{$screen-sm-min})',
                                      'md' '(min-width: #{$screen-md-min})',
                                      'lg' '(min-width: #{$screen-lg-min})',
                                      'xl' '(min-width: #{$screen-xl-min})'
                                    );
$breakpoints-desc:                  (
                                      'xs' '(max-width: #{$screen-xs-max})',
                                      'sm' '(max-width: #{$screen-sm-max})',
                                      'md' '(max-width: #{$screen-md-max})',
                                      'lg' '(max-width: #{$screen-lg-max})'
                                    );
$breakpoints-custom:   (
                          'xxs' '(min-width: 375px)',
                          'xs-sm' '(min-width: 520px)',
                          'lg-xl' '(min-width: 1280px)'
                        );

$screen-box-min:                     520px / 16px * 1em !default;

// /**
//  * Miscellaneous
//  */

$border-radius-default:             2px;
$border-radius-small:               2px;
$border-radius-large:               2px;


// ----------------------------------------*\
//   COMPONENTS
// \*----------------------------------------

// /**
//  * Buttons
//  */

$btn-use-padding-ratio:             false;

$btn-padding-v:                     rem($rhythm-spacing-base - 1);
$btn-padding-h:                     1.5rem;
$btn-font:                          $font-default;
$btn-color:                         $brand-color;
$btn-border:                        none;
$btn-bkg:                           transparent;
$btn-border-radius:                 $border-radius-default;

$btn-small-padding-v:               rem($rhythm-spacing-base - 1);
$btn-small-padding-h:               1rem;
$btn-small-font:                    $font-small;
$btn-small-border-radius:           $border-radius-small;

$btn-large-padding-v:               rem(11);
$btn-large-padding-h:               2rem;
$btn-large-font:                    $font-h6;
$btn-large-border-radius:           $border-radius-large;

$btn-default-color:                 $text-color !default;
$btn-default-bkg-color:             $alt-color-lighter;
$btn-default-hover-bkg-color:       lighten($btn-default-bkg-color, 3%);
$btn-default-active-bkg-color:      darken($btn-default-bkg-color, 5%);

$btn-primary-color:                 white !default;
$btn-primary-bkg-color:             $brand-color;
$btn-primary-hover-bkg-color:       lighten($btn-primary-bkg-color, 8%);
$btn-primary-active-bkg-color:      darken($btn-primary-bkg-color, 5%);


// /**
//  * Forms
//  */

$field-use-padding-ratio:           false;

$field-padding-v:                   $btn-padding-v;
$field-padding-h:                   $btn-padding-h;

$field-color:                       inherit;
$field-font:                        $btn-font;

$field-bkg-color:                   white;
$field-border:                      1px solid $alt-color-light;
$field-focus-border-color:          $alt-color;
$field-border-radius:               $border-radius-default;

$field-disabled-bkg-color:          $alt-color-lighter;
$field-disabled-color:              $alt-color;

$field-small-padding-v:             $btn-small-padding-v;
$field-small-padding-h:             $btn-small-padding-h;
$field-small-font:                  $btn-small-font;
$field-small-border-radius:         $border-radius-small;

$field-large-padding-v:             $btn-large-padding-v;
$field-large-padding-h:             $btn-large-padding-h;
$field-large-font:                  $btn-large-font;
$field-large-border-radius:         $border-radius-large;

$field-help-color:                  $alt-color-light;


// /**
//  * Lists and Borders
//  */

$border-color:                      $alt-color-light;
$list-separator-style:              1px solid $border-color !default;


// /**
//  * Box
//  */

// $box-default-color:                 inherit !default;
// $box-default-bkg-color:             $alt-color-lighter !default;

// $box-primary-color:                 white !default;
// $box-primary-bkg-color:             $brand-color !default;


// /**
//  * Grid
//  */

$grid-flex:                         true;


// /**
//  * Widths
//  */

// $widths-columns:                    6,5,4,3,2,1 !default;
$widths-breakpoints:                $breakpoints-default;


// /**
//  * Media
//  */

// $media-gutter:                      $spacing-unit-default !default;


// /**
//  * Media responsive
//  */

// $media-collapse:                    $screen-sm-max !default;


// /**
//  * Mosaic responsive
//  */

// $mosaic-collapse:                   $screen-sm-max !default;
