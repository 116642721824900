/*----------------------------------------*\
  BUBBLES
\*----------------------------------------*/

.bubble {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 15rem;
  height: 15rem;
  padding: 2rem;

  text-align: center;

  border-radius: 100%;
  background-color: white;

  align-items: center;
  justify-content: center;
}


.bubble__title {
  font-weight: bold;

  @extend %text-h3;
}

.bubble__text {
  color: $muted-color;

  @extend %text-h6;
}

/**
 * Display modifiers
 */

.bubble--tiny {
  width: 6rem;
  height: 6rem;
  padding: 1.5rem;
}

.bubble--small {
  width: 11rem;
  height: 11rem;
}

.bubble--large {
  width: 19rem;
  height: 19rem;
}

.bubble--huge {
  width: 23rem;
  height: 23rem;
}

.bubble__transparent {
  padding: 4rem;

  transition: $transition-hover;
  transform: scale(1.1);

  background: rgba($text-color, .2);

  .icon {
    fill: white;
  }
}

.bubble--benefits {
  position: absolute;
  top: 0;
  right: 0;

  display: none;

  @include media('sm') {
    display: flex;
  }
  @include media('md') {
    transform: translate(25%, -25%);

    box-shadow: $material-shadow-elevated;
  }
}



/**
 * Alternative colors
 */

.bubble-collection {
  display: flex;

  width: 100%;
  height: 50rem;

  align-items: center;
  justify-content: center;
}

.bubble-collection__content {
  position: relative;

  width: 19rem;
  height: 19rem;
  .bubble {
    position: absolute;
    top: 0;
    left: 0;
    &:nth-child(1) {
      transform: translate(-18rem, -15rem);
    }
    &:nth-child(2) {
      transform: translate(-3rem, 15rem);
    }
    &:nth-child(3) {
      transform: translate(12rem, -8rem);
    }
  }
}
