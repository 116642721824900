/*----------------------------------------*\
  FOOTER
\*----------------------------------------*/

.footer-links {
  ul {
    column-count: 2;
    column-gap: $spacing-unit-default;
    @include media('lg') {
      column-count: 3;
    }
    li {
      display: inline-block;
      width: 100%;
    }
  }
}
