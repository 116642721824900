/*----------------------------------------*\
  LIST STACKED
\*----------------------------------------*/

/*
  TODO: Check interaction with .colums
  for list-stacked--divied, so no :not
  selector is needed.
*/

.list-stacked,
%list-stacked {
  @extend %list;
  > li {
    padding: 1rem 0;
  }
  &:not(.list-stacked--divided) li {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

// Add separators
.list-stacked--divided,
%list-stacked--divided {
  li {
    border-bottom: $list-separator-style;
    @include media('sm') {
      border-top: $list-separator-style;
      margin-bottom: -1px;
    }
  }
}

/**
 * Sizes
 */

.list-stacked--tight,
%list-stacked--tight {
  > li {
    padding: 0;
  }
}

.list-stacked--tiny,
%list-stacked--tiny {
  > li {
    padding: $spacing-unit-tiny/2 0;
  }
}

.list-stacked--small,
%list-stacked--small {
  > li {
    padding: $spacing-unit-small/2 0;
  }
}

.list-stacked--large,
%list-stacked--large {
  > li {
    padding: $spacing-unit-large/2 0;
  }
}

.list-stacked--huge,
%list-stacked--huge {
  > li {
    padding: $spacing-unit-huge/2 0;
  }
}

/**
 * With Icons
 */
.list-stacked--icon {
  > li {
    position: relative;
    padding-left: 3rem;
    &:before {
      font-size: .8em;
      font-weight: bold;

      display: block;

      width: 3rem;

      content: '✓';
      position: absolute;
      left: 0;
    }
  }
}

