/*----------------------------------------*\
  NAVIGATION
\*----------------------------------------*/


.nav {
  position: fixed;
  z-index: $z-index-nav;
  top: 0;

  width: 100%;

  transform: translate3d(0,0,0);
  backface-visibility: hidden;

  &.nav--prepare-open {
    height: 100vh !important;
  }
  @include media('lg') {
    transition: none;
    position: relative;

    height: auto !important;
    &.nav--open {
      height: auto;
    }
  }
  // .section,
  // .section__body {
  //   position: relative;
  // }
  .b-group {
    background-color: rgba(250, 250, 250, .98);
    box-shadow: 0 1px 0 0 rgba($alt-color, .2);
  }
}

.nav-overlay {
  background-color: rgba($color-grey-200,0);
  -webkit-backdrop-filter: blur(0px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: $z-index-page-overlay;
  transition: $transition-context-change;
  display: none;
  .nav--prepare-open & {
    display: block;
    @include media('md') {
      display: none;
    }
  }
  .nav--open & {
    background-color: rgba($color-grey-200,0.9);
    -webkit-backdrop-filter: blur(10px);
    .no-backdropfilter & {
      background-color: $color-grey-200;
    }
  }
}

.nav-group__logo {
  display: block;

  width: 7rem;
  height: 6rem;

  background-image: url('../images/logo.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100%;
  .b-organization & {
    flex: 0 0 7rem;
    @include media('lg') {
      height: 4rem;
    }
  }
  .b-group & {
    border-radius: $border-radius-default;
    background-color: white;
    background-image: url('../images/logo.png');
    background-position: center center;
    background-size: 85%;
    box-shadow: $material-shadow-default;
    flex: 0 0 14rem;
    height: 9rem;
    margin-top: 0rem;
    margin-bottom: -5rem;
    @include media('md') {
      flex: 0 0 20rem;
      height: 12rem;
      margin-bottom: -10rem;
      margin-top: -1.5rem;
    }
    @include media('lg') {
      flex: 0 0 20rem;
      height: 12rem;
      margin-bottom: -10rem;
      margin-top: -2.5rem;
    }
    @include media('xl') {
      flex: 0 0 24rem;
      height: 15rem;
    }
  }
}

.nav-group__menu {
  flex: 0 0 100%;
  overflow: hidden;

  transition: $transition-context-change;

  opacity: 0;

  .b-organization & {
    max-height: 4rem;
  }

  .b-group & {
    height: 0;
    @include media('lg') {
      height: auto;
    }
  }

  .nav--open & {
    opacity: 1;
    .b-organization & {
      max-height: 30rem;
    }
    .b-group & {
      height: calc(100vh - 12rem);
    }
  }

  @include media('lg') {
    flex: 1 1 0;
    margin-left: 2rem;
    opacity: 1;
    height: none;
    height: initial;
    max-height: none;
    max-height: initial;
    margin-left: 2rem;
  }
  @include media('lg') {
    .b-organization & {
      padding-left: 16rem;
    }
  }
}

.nav-group {
  z-index: $z-index-nav-group;
  display: flex;
  flex-wrap: wrap;
  .nav__menu-list {
    .b-group & {
      margin-top: 3rem;
    }
    li {
      padding: 1rem 0;
      @include font-values($font-menu);
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    @include media('md') {
      .b-group & {
        margin-top: 6rem;
      }
      li {
        display: inline-block;

        margin-right: 1rem;
      }
    }
    @include media('lg') {
      li {
        margin-right: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .b-group & {
        padding-left: 2rem;
        margin-top: 0;
      }
      .b-organization & {
        padding-left: 1rem;
      }
    }
    a {
      padding: 2rem 0;

      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &.is-active {
        opacity: 1;
      }
      .path-frontpage & {
        opacity: 1;
      }
    }
    .b-organization & {
      padding-bottom: 5rem;

      @include media('lg') {
        padding-bottom: 0;
      }
    }
  }
}

.section--nav-meta {
  position: fixed;
  z-index: $z-index-nav-meta;
  background-color: transparent;
  bottom: 0;
  left: 0;

  display: none;

  width: 100%;
  height: 5.5rem;

  transition: $transition-context-change;
  transition-timing-function: cubic-bezier(0.88, -0.15, 0.47, 1.3);
  transform: translateY(100%);
  opacity: 0;

  @include media('md') {
    position: relative;
    height: 0;

    display: block;

    width: auto;
    margin-right: 1rem;

    background-color: $color-grey-50;
    opacity: 1;
    transform: none;
    transition: none;
  }
  .nav--prepare-open & {
    display: block;
  }
  .nav--open & {
    transform: none;
    opacity: 1;
  }
}

.nav-meta {

}

// .nav-gobal__meta-list {
.nav-meta {
  display: flex;
  justify-content: space-between;
  li {
    padding: 1rem;
  }
  @include media('md') {
    position: absolute;
    top: 1.5rem;
    right: 16rem;
  }
  @include media('lg') {
    right: 0;
  }
  @include media('xl') {
    top: 2rem;
  }
}

.nav-group__menu-btn {
  flex: 0 0 12rem;
  margin-left: auto;

  padding: 1.5rem 1.25rem 1.5rem 2rem;

  transform: translateX(2rem);

  line-height: 3rem;

  .icon {
    width: 3rem;
    height: 3rem;
  }

  #menu__line-1,
  #menu__line-2,
  #menu__line-3 {
    transition: $transition-context-change;
  }
  #menu__line-1 {
    transform-origin: 2px 6px;
  }
  #menu__line-2 {
    transform-origin: center center;
  }
  #menu__line-3 {
    transform-origin: 22px 18px;
  }
  .nav--open & {
    #menu__line-1 {
      // transform: translateY(6px) rotate(-45deg) scaleX(0.5);
      transform: translate(3px,-1px) rotate(45deg) scaleX(0.5);
    }
    #menu__line-2 {
      transform: rotate(-45deg);
    }
    #menu__line-3 {
      transform: translate(-3px,1px) rotate(45deg) scaleX(0.5);
    }
  }

  @include media('lg') {
    display: none;
  }
}

.section--nav-group {
  background-color: rgba($color-grey-50, 0.93);
  -webkit-backdrop-filter: blur(10px);

  transition: $transition-context-change;

  .no-backdropfilter & {
    background-color: $color-grey-50;
  }

  z-index: $z-index-nav-group;

  @include media('md') {
    padding: 1rem 0;
    .b-organization & {
      height: 8rem;
    }
    .b-group & {
      height: 8rem;
    }
    .nav--open & {
      .b-organization & {
        height: 18rem;
      }
      .b-group & {
        height: 20rem;
      }
    }
  }
  @include media('lg') {
    padding-top: 2rem;
    .b-organization & {
      height: 15rem;
    }
    .b-group & {
      height: 8rem;
    }
  }
  @include media('xl') {
    .b-group & {
      height: 9rem;
    }
  }
}

.section--nav-organization {
  display: none;

  z-index: $z-index-nav-organization;

  box-shadow: 0 2px 8px 0 rgba(128, 140, 136, .18);
  transition: $transition-context-change;

  position: absolute;
  width: 100%;
  top: 6rem;

  .nav--open & {
    transform: translateY(26rem);
    @include media('md') {
      transform: none;
    }
  }

  @include media('md') {
    position: relative;
    top: initial;
  }
  @include media('lg') {

  }
  .b-organization & {
    display: block;
  }
}

.nav-organization {
  position: relative;
  z-index: $z-index-nav-organization;
}

.nav-organization__name {
  font-size: $font-size-small;

  display: block;

  padding: .5rem 0;

  transition: $transition-context-change;

  z-index: 1;
  position: relative;

  .nav--open & {
    transform: translateY(1rem);
    @include media('md') {
      transform: none;
    }
  }
  @include media('md') {
    transition: none;

    font-size: $font-size-default;

    position: absolute;
    top: -4.5rem;
    left: 24rem;

    padding: 0;
  }
  @include media('lg') {
    left: 26rem;
  }
}

.nav-organization__logo {
  position: absolute;
  top: -4rem;
  left: 50%;

  display: block;

  width: 12rem;
  height: 6rem;
  margin-left: -6rem;
  padding: 1rem;

  border-radius: $border-radius-default;
  background-color: white;

  div {
    background-repeat: no-repeat;
    background-position: left center;
    background-position: center center;
    background-size: contain;
    height: 100%;
    width: 100%;
  }

  @include media('md') {
    top: -6rem;
    left: 10rem;

    margin-left: 0;
  }
  @include media('lg') {
    top: -7rem;
    left: 0;

    width: 24rem;
    height: 12rem;

    padding: 1.5rem;
  }
  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 50%;

    content: '';

    border-radius: $border-radius-default;
    box-shadow: 0 2px 8px 0 rgba(128, 140, 136, .10);
  }
}

.nav-organization__menu {
  overflow: hidden;

  max-height: 0;

  transition: $transition-context-change;

  @include media('md') {
    overflow: visible;

    max-height: none;
    max-height: initial;
  }
  @include media('lg') {
    padding-left: 26rem;
  }
  .nav--open & {
    max-height: 50rem;
  }
  .nav__menu-list {
    @include media('md') {
      overflow: hidden;
    }
    li {
      padding: 0;
      &:first-child {
        margin-top: 1rem;
      }
      &:last-child {
        margin-bottom: 1rem;
      }
      @include media('md') {
        display: block;
        float: left;

        margin-right: 2rem;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include media('md') {
        margin-right: 3rem;
      }
    }
    a {
      font-weight: bold;

      display: block;

      width: 100%;
      padding: 1rem 0;

      @include font-values($font-menu);

      @include media('md') {
        opacity: 0.5;
        padding: 1.5rem 0;
      }

      &:hover, &.is-active {
        opacity: 1;
      }
    }
  }
}


/**
 * Possible Refactoring
 */

// $nav-group-height:         5rem;
// $nav-group-height-md:      7rem;
// $nav-group-height-lg:      13rem;

// $nav-organization-height:   8rem;

// .nav {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   overflow: visible;
//   .section__body {
//     margin-top: 0;
//     margin-bottom: 0;
//   }
// }

// .nav__list {
//   li {
//     padding: 0;
//     @include media('lg') {
//       margin-right: 2rem;
//     }
//   }
//   a {
//     display: inline-block;
//   }
// }

// /**
//  * Navigation Meta
//  */

// .nav-meta {
//   display: none;
//   z-index: $z-index-nav-meta;
// }

// /**
//  * Navigation Group
//  */

// .nav-group {
//   z-index: $z-index-nav-group;
//   height: $nav-group-height;

//   background-color: $color-grey-50;

//   @include media('md') {
//     height: $nav-group-height-md;
//   }

//   @include media('lg') {
//     height: $nav-group-height-lg;
//   }
// }

// .nav-group__body {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// }

// .nav-group__logo {
//   display: block;

//   .b-organization & {
//     height: $nav-group-height;
//     flex: 0 0 8rem;

//     background-image: url('../images/logo.svg');
//     background-repeat: no-repeat;
//     background-position: left center;
//     background-size: auto 2.5rem;
//     @include media('md') {
//       height: $nav-group-height-md;
//       background-size: auto 3rem;
//       flex: 0 0 9rem;
//     }
//   }
//   .b-group & {
//     border-radius: $border-radius-default;
//     background-color: white;
//     background-image: url('../images/logo.png');
//     background-position: center center;
//     background-size: 85%;
//     box-shadow: $material-shadow-default;
//     width: $nav-group-height * 3;
//     height: $nav-group-height;
//     margin-top: 0rem;
//     margin-bottom: -5rem;
//     @include media('md') {
//       width: 20rem;
//       height: 12rem;
//       margin-bottom: -10rem;
//       margin-top: -1.5rem;
//     }
//     @include media('lg') {
//       width: 24rem;
//       height: 15rem;
//       margin-bottom: -10rem;
//       margin-top: -1.5rem;
//     }
//   }
// }

// .nav-group__btn {
//   flex: 0 0 auto;
//   @include media('lg') {
//     display: none;
//   }
//   .icon {
//     width: 3rem;
//     height: 3rem;
//   }
// }

// .nav-group__menu {
//   flex: 0 0 100%;
//   height: 0;
//   overflow: hidden;
//   @include media('lg') {
//     height: auto;
//     flex: 1 1 auto;
//     margin-left: 17rem;
//   }
//   .nav__list {
//     @include media('lg') {
//       overflow: hidden;
//       li {
//         float: left;
//       }
//       a {
//         margin: 2rem 0;
//       }
//     }
//   }
// }

// /**
//  * Navigation Organization
//  */

// .nav-organization {
//   z-index: $z-index-nav-organization;
//   top: $nav-group-height;
//   box-shadow: 0 2px 8px 0 rgba(128, 140, 136, .18);
//   @include media('md') {
//     top: $nav-group-height-md;
//   }
//   @include media('lg') {
//     top: $nav-group-height-lg;
//   }
// }

// .nav-organization__body {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   @include media('md') {
//     justify-content: flex-start;
//   }
// }

// .nav-organization__logo {
//   display: block;

//   position: relative;

//   width: 12rem;
//   height: 6rem;

//   margin-top: -4rem !important;
//   margin-bottom: -2rem;
//   padding: 1rem;

//   border-radius: $border-radius-default;
//   background-color: white;

//   div {
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-size: contain;
//     height: 100%;
//     width: 100%;
//   }
//   &:before {
//     position: absolute;
//     z-index: -1;
//     top: 0;
//     left: 0;

//     display: block;

//     width: 100%;
//     height: 50%;

//     content: '';

//     border-radius: $border-radius-default;
//     box-shadow: 0 2px 8px 0 rgba(128, 140, 136, .10);
//   }
//   @include media('md') {
//     margin-left: 10rem;
//     margin-top: -6rem !important;
//   }
//   @include media('lg') {
//     margin-left: 0;
//     margin-top: -6rem !important;
//     margin-bottom: -6rem;

//     width: 24rem;
//     height: 12rem;
//   }
// }

// .nav-organization__name {
//   flex: 0 0 100%;
//   @include media('md') {
//     flex: 1 1 auto;
//     margin-top: -4.5rem;
//     margin-left: 2rem;
//   }
// }

// .nav-organization__menu {
//   height: 0;
//   overflow: hidden;
//   a {
//     font-weight: bold;
//   }
//   @include media('md') {
//     height: auto;
//     flex: 0 0 100%;
//     .nav__list {
//       overflow: hidden;
//     }
//     li {
//       float: left;
//     }
//     a {
//       margin: 1.5rem 0;
//     }
//   }
//   @include media('lg') {
//     margin-left: 26rem;
//   }
// }
