/*----------------------------------------*\
  BUTTON
\*----------------------------------------*/

.btn,
%btn {
  font-weight: bold;
  line-height: inherit;

  position: relative;

  display: inline-block;

  padding: 0;

  cursor: pointer;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;

  border: none;
  border-radius: 0;
  background: none;

  @include font-values($btn-font);
  // Cleaner font rendering
  // <button> doesn’t inherit
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}


/**
 * Variants
 */

// Use all the width available
.btn--block,
%btn--block {
  display: block;

  width: 100%;
}

// Remove default styling for special buttons
.btn--link,
%btn--link {
  white-space: normal;
  text-align: left;

  transition: $transition-hover;

  @include font-values($font-h6);

  &:after, &:before {
    transition: $transition-hover;
  }

  &:after {
    content: "→";
    display: inline-block;
    margin-left: 0.3em;
  }
  &:before {
    content: "—";
    display: block;
    position: absolute;
    right: 0em;
    width: 1em;
    opacity: 0;
  }
  &:hover, .box:hover & {
    color: $text-color;
    &:after, &:before {
      transition: $transition-hover-bounce;
    }
    &:after {
      transform: translateX(1.5rem);
    }
    &:before {
      opacity: 1;
      transform: translateX(1.4rem);
    }
  }
}


/**
 * Styles
 */

.btn--default,
.btn--secondary,
.btn--alt {
  overflow: hidden;

  padding: $btn-padding-v $btn-padding-h;

  transition: $transition-hover;

  border: 1px solid;
  border-radius: $btn-border-radius;
}

.btn--default,
.btn--secondary {
  &:hover,
  &:focus,
  &:active {
    opacity: .8;
  }
}

.btn--default,
%btn--default {
  color: white;
}

.btn--alt {
  background: $alt-color-lighter;
  border-color: $alt-color-lighter;
  color: $text-color;
}



/**
 * States
 */

.btn--disabled,
.btn[disabled],
%btn--disabled {
  cursor: not-allowed;

  opacity: .2;
}


/**
 * Sizes
 */

.btn--small,
%btn--small {
  padding: $btn-small-padding-v $btn-small-padding-h;

  border-radius: $btn-small-border-radius;

  @include font-values($btn-small-font);
}

.btn--large,
%btn--large {
  padding: $btn-large-padding-v $btn-large-padding-h;

  border-radius: $btn-large-border-radius;

  @include font-values($btn-large-font);
}


@include media('lg') {
  .btn--responsive {
    padding: $btn-large-padding-v $btn-large-padding-h;

    border-radius: $btn-large-border-radius;

    @include font-values($btn-large-font);
  }
}



/**
 * Button Groups
 */

.btn-group {
  // background: rgba(white, 0.8);
  // -webkit-backdrop-filter: blur(12px);
  display: flex;
  .btn {
    border-radius: 0;
    padding: 1.5rem 2rem;
    font-size: rem(map-get($rhythm-size-xxs, size));
    background: rgba(white,0.8);
    display: block;
    flex: 1;
    border-radius: 0;
    color: $alt-color-dark;
    .backdropfilter & {
      -webkit-backdrop-filter: blur(12px);
    }
    @include media('xxs', $breakpoints-custom) {
      padding: 2rem;
    }
    @include media('sm') {
      font-size: rem(map-get($rhythm-size-xs, size));
      flex: 0 0 auto;
      // border-radius: $btn-border-radius;
      &:first-child {
        border-top-left-radius: $btn-border-radius;
      }
      &:last-child {
        border-top-right-radius: $btn-border-radius;
      }
    }
    .pill {
      display: none;
      @include media('xxs', $breakpoints-custom) {
        display: inline-block;
      }
    }
    .b-organization & {

    }
    &:hover {
      opacity: 0.8;
    }
    &:not(.active) {
      .pill {
        background: $alt-color;
      }
    }
    &.active {
      background: white;
      color: $text-color;
      // pointer-events: none;
      .pill {
        display: none;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 2rem;
        width: 2rem;
        bottom: -1.5rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        @include media('sm') {
          bottom: -1rem;
        }
        .b-group & {
          background: $brand-color;
        }
        .b-organization & {
          background: $alt-color-lighter;
          @include media('sm') {
            background: white;
          }
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

/**
 * Button with icon
 */

.btn--icon {
  .icon {
    max-height: 1em;
    max-width: 1em;
    float: left;
    margin-right: 0.3em;
    margin-top: 0.2em;
  }
}

/**
 * Login Button
 */
.btn--login {
  font-family: 'EKW Helvetica Condensed';
  font-size: 1.33333333333rem;
  line-height: 2rem;

  padding: .4rem 1rem .2rem;

  text-transform: uppercase;

  border-color: $color-grey-400;
  background: white;
  .icon {
    max-width: 2.2rem;
    margin-right: .75rem;
    padding-right: .75rem;

    border-right: 1px solid $color-grey-400;
  }
}

